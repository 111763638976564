.dropdown{
    &:focus-within { 
        .dropdown-menu {
            opacity:1;
            transform: translate(0) scale(1);
            visibility: visible;
        }

        svg {
            transform: rotate(180deg);
        }
    }
}
