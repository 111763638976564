.phone-pic {
    img {
        &:nth-of-type(1) {
            position: absolute;
            margin-top: -10px;
            margin-left: -19px;
            height: 670px;
            max-width: 528px;
            @media screen and (min-width: 376px) {
                height: 729px;
            }
            @media screen and (min-width: 415px) {
                margin-top: 0;
                height: 829px;
            }
        }
        &:nth-of-type(2) {
            position: relative;
            z-index: 1;
            width: 260px;
            object-fit: contain;
            object-position: center;
            @media screen and (min-width: 376px) {
                width: 280px;
            }
            @media screen and (min-width: 415px) {
                width: 323px;
            }
        }
    }
}
