// tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

// setup
@import "./global/init";

//components
@import "../components/dropdown/styles/init";

// pages
@import "../containers/home/styles/init";

//containers
@import "../containers/support/styles/init";
@import "../containers/experience/styles/init";

// fonts Gilroy
@layer base {
  @font-face {
    font-family: 'Gilroy Regular';
    font-weight: 400;
    src: url(../assets/fonts/Gilroy-Regular.eot);
    src: url(../assets/fonts/Gilroy-Regular.eot?#iefix) format('embedded-opentype'),
          url(../assets/fonts/Gilroy-Regular.woff) format("woff"),
          url(../assets/fonts/Gilroy-Regular.woff2) format('woff2'),
          url(../assets/fonts/Gilroy-Regular.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Gilroy Medium';
    font-weight: 500;
    src: url(../assets/fonts/Gilroy-Medium.eot);
    src: url(../assets/fonts/Gilroy-Medium.eot?#iefix) format('embedded-opentype'),
          url(../assets/fonts/Gilroy-Medium.woff) format("woff"),
          url(../assets/fonts/Gilroy-Medium.woff2) format('woff2'),
          url(../assets/fonts/Gilroy-Medium.ttf) format('truetype');
  }
  @font-face {
    font-family: 'Albra Regular';
    font-weight: 400;
    src: url(../assets/fonts/Albra-Regular.eot);
    src: url(../assets/fonts/Albra-Regular.eot?#iefix) format('embedded-opentype'),
          url(../assets/fonts/Albra-Regular.woff) format("woff"),
          url(../assets/fonts/Albra-Regular.woff2) format('woff2'),
          url(../assets/fonts/Albra-Regular.ttf) format('truetype');
  }
}

@layer base {
  body {
    &::-webkit-scrollbar-track {
      background-color: #fff;
    }
    //@apply bg-mustard;
  }
}

@layer components {
  .max-full {
    max-width: 100%!important;
  }
  .min-full {
    min-width: 100%!important;
  }
  .odd-margin:first-child {
    @apply ml-2.5;
  }
  .odd-margin {
    &:nth-child(odd){
      @apply mt-22;
    }
    &:nth-child(3n){
      @apply mt-20;
    }
    &:nth-child(4n){
      @apply mt-24;
    }
    &:nth-child(5n){
      @apply mt-0;
    }
    &:nth-child(7n){
      @apply mt-12;
    }
    &:nth-child(8n){
      @apply mt-1;
    }
    &:nth-child(odd) div:first-child {
      @apply md:-right-5;
    }
    &:nth-child(even) div:last-child {
      @apply md:-right-8;
    }
  }
}
