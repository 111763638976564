#experience {

    .collage {
        div {
            &:nth-child(1) {
                width: 100%;
                @media screen and (min-width: 767px) {
                    width: 428px;
                }
                img {
                    //height: 298px;
                    @media screen and (min-width: 767px) {
                        height: 569px;
                    }
                }
            }
            &:nth-child(2) {
                width: 100%;
                p, h6 {
                    //margin-left: -35px;
                }
                @media screen and (min-width: 767px) {
                    width: 428px;
                    align-self: flex-end;

                    p, h6 {
                        margin-left: 0;
                    }
                }
                @media screen and (min-width: 1070px) {
                    margin-top: -384px;
                }
                img {
                    //height: 361px;
                    @media screen and (min-width: 767px) {
                        height: 612px;
                    }
                }
            }
            &:nth-child(3) {
                width:100%;
                margin-top: 0;

                p {
                    width: 100%;
                    max-width: 377px;
                }
                @media screen and (min-width: 767px) {
                    width: 519px;
                }
                @media screen and (min-width: 1070px) {
                    margin-top: -236px;
                }
                img {
                    //height: 268px;
                    @media screen and (min-width: 767px) {
                        height: 475px;
                    }
                }
            }


            div {
                width: 100% !important;
            }
        }
    }

}
